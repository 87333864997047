/* Loading dots styled  */
// @-webkit-keyframes dotFlashing {
//   0% {
//     opacity: 0;
//   }
//   50% {
//     opacity: 1;
//   }
//   100% {
//     opacity: 0;
//   }
// }
// @keyframes dotFlashing {
//   0% {
//     opacity: 0;
//   }
//   50% {
//     opacity: 1;
//   }
//   100% {
//     opacity: 0;
//   }
// }

.loading-flashing-dots {
  display: flex;
  justify-content: center;
}

.loading-flashing-dots > div {
  width: 10px;
  height: 10px;
  margin: 2px 4px;
  border-radius: 50%;
  background-color: #a3a1a1;
  opacity: 1;
  -webkit-animation: loading-flashing-dots 0.6s infinite alternate;
  animation: loading-flashing-dots 0.6s infinite alternate;
}

.loading-flashing-dots > div:nth-child(2) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.loading-flashing-dots > div:nth-child(3) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
@-webkit-keyframes loading-flashing-dots {
  to {
    opacity: 0.1;
    /* transform: translateY(-16px); */
  }
}

@keyframes loading-flashing-dots {
  to {
    opacity: 0.1;
    /* transform: translateY(-16px); */
  }
}
