* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  background-color: rgb(255 255 255);
}
a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

/* // Start react-stylable-diff -> Listing Changes diff view */
.Difference > del {
  background-color: #ffeef0 !important;
  text-decoration: none !important;
}

.Difference > ins {
  background-color: #e6ffed !important;
  text-decoration: none !important;
}
/* // End react-stylable-diff -> Listing Changes diff view */
