.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 6rem;
  min-height: 100vh;
}

.description {
  display: inherit;
  justify-content: inherit;
  align-items: inherit;
  font-size: 0.85rem;
  max-width: var(--max-width);
  width: 100%;
  z-index: 2;
  font-family: var(--font-mono);
}

.description a {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.description p {
  position: relative;
  margin: 0;
  padding: 1rem;
  background-color: rgba(var(--callout-rgb), 0.5);
  border: 1px solid rgba(var(--callout-border-rgb), 0.3);
  border-radius: var(--border-radius);
}

.code {
  font-weight: 700;
  font-family: var(--font-mono);
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(25%, auto));
  width: var(--max-width);
  max-width: 100%;
}

.card {
  padding: 1rem 1.2rem;
  border-radius: var(--border-radius);
  background: rgba(var(--card-rgb), 0);
  border: 1px solid rgba(var(--card-border-rgb), 0);
  transition: background 200ms, border 200ms;
}

.card span {
  display: inline-block;
  transition: transform 200ms;
}

.card h2 {
  font-weight: 600;
  margin-bottom: 0.7rem;
}

.card p {
  margin: 0;
  opacity: 0.6;
  font-size: 0.9rem;
  line-height: 1.5;
  max-width: 30ch;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 4rem 0;
}

.center::before {
  background: var(--secondary-glow);
  border-radius: 50%;
  width: 480px;
  height: 360px;
  margin-left: -400px;
}

.center::after {
  background: var(--primary-glow);
  width: 240px;
  height: 180px;
  z-index: -1;
}

.center::before,
.center::after {
  content: '';
  left: 50%;
  position: absolute;
  filter: blur(45px);
  transform: translateZ(0);
}

.logo,
.thirteen {
  position: relative;
}

.thirteen {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 75px;
  padding: 25px 10px;
  margin-left: 16px;
  transform: translateZ(0);
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: 0px 2px 8px -1px #0000001a;
}

.thirteen::before,
.thirteen::after {
  content: '';
  position: absolute;
  z-index: -1;
}

/* Conic Gradient Animation */
.thirteen::before {
  animation: 6s rotate linear infinite;
  width: 200%;
  height: 200%;
  background: var(--tile-border);
}

/* Inner Square */
.thirteen::after {
  inset: 0;
  padding: 1px;
  border-radius: var(--border-radius);
  background: linear-gradient(
    to bottom right,
    rgba(var(--tile-start-rgb), 1),
    rgba(var(--tile-end-rgb), 1)
  );
  background-clip: content-box;
}

.recharts-legend-item:hover {
  text-decoration: underline;
}

.stack-start {
  margin-right: 0 !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.stack-end {
  margin-left: 0 !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.chart-tooltip {
  background: white;
  box-shadow: normal;
  border: 1px solid #f3f4f6;
  border-radius: 3px;
  padding: 10px;
}

/* Enable hover only on non-touch devices */
@media (hover: hover) and (pointer: fine) {
  .card:hover {
    background: rgba(var(--card-rgb), 0.1);
    border: 1px solid rgba(var(--card-border-rgb), 0.15);
  }

  .card:hover span {
    transform: translateX(4px);
  }
}

@media (prefers-reduced-motion) {
  .thirteen::before {
    animation: none;
  }

  .card:hover span {
    transform: none;
  }
}

/* Mobile */
@media (max-width: 700px) {
  .content {
    padding: 4rem;
  }

  .grid {
    grid-template-columns: 1fr;
    margin-bottom: 120px;
    max-width: 320px;
    text-align: center;
  }

  .card {
    padding: 1rem 2.5rem;
  }

  .card h2 {
    margin-bottom: 0.5rem;
  }

  .center {
    padding: 8rem 0 6rem;
  }

  .center::before {
    transform: none;
    height: 300px;
  }

  .description {
    font-size: 0.8rem;
  }

  .description a {
    padding: 1rem;
  }

  .description p,
  .description div {
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100%;
  }

  .description p {
    align-items: center;
    inset: 0 0 auto;
    padding: 2rem 1rem 1.4rem;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid rgba(var(--callout-border-rgb), 0.25);
    background: linear-gradient(
      to bottom,
      rgba(var(--background-start-rgb), 1),
      rgba(var(--callout-rgb), 0.5)
    );
    background-clip: padding-box;
    backdrop-filter: blur(24px);
  }

  .description div {
    align-items: flex-end;
    pointer-events: none;
    inset: auto 0 0;
    padding: 2rem;
    height: 200px;
    background: linear-gradient(to bottom, transparent 0%, rgb(var(--background-end-rgb)) 40%);
    z-index: 1;
  }
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
  .grid {
    grid-template-columns: repeat(2, 50%);
  }
}

@media (prefers-color-scheme: dark) {
  .vercelLogo {
    filter: invert(1);
  }

  .logo,
  .thirteen img {
    filter: invert(1) drop-shadow(0 0 0.3rem #ffffff70);
  }
}

@keyframes rotate {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.tippy-box[data-theme~='amztools'] {
  color: black;
  min-width: 22em;
  background: white;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  padding: 10px;
  li {
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    // column-gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  // li.maxbid {
  // grid-template-columns: 0.4fr 0.8fr;
  // }
  .tippy-content {
    padding: 0;
    max-height: 30vh;
    overflow-y: scroll;

    /* total width */
    &::-webkit-scrollbar {
      background-color: #fff;
      width: 16px;
    }

    /* background of the scrollbar except button or resizer */
    &::-webkit-scrollbar-track {
      background-color: #fff;
    }
    &::-webkit-scrollbar-track:hover {
      background-color: #f4f4f4;
    }

    /* scrollbar itself */
    &::-webkit-scrollbar-thumb {
      background-color: #babac0;
      border-radius: 16px;
      border: 5px solid #fff;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #a0a0a5;
      border: 4px solid #f4f4f4;
    }

    /* set button(top and bottom of the scrollbar) */
    &::-webkit-scrollbar-button {
      display: none;
    }

    // overflow-y: scroll;
    // @extend .hide-scrollbar;

    // -ms-overflow-style: none; /* for Internet Explorer, Edge */
    // scrollbar-width: none; /* for Firefox */
    // overflow-y: scroll;
    // &::-webkit-scrollbar {
    //   display: none; /* for Chrome, Safari, and Opera */
    // }

    // background:
    //   /* Shadow covers */
    //   linear-gradient(white 30%, rgba(255,255,255,0)),
    //   linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,

    //   /* Shadows */
    //   radial-gradient(50% 0, farthest-side, rgba(0,0,0,.2), rgba(0,0,0,0)),
    //   radial-gradient(50% 100%,farthest-side, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;

    // background:
    //   /* Shadow covers */
    //   linear-gradient(white 30%, rgba(255,255,255,0)),
    //   linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,

    //   /* Shadows */
    //   radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.2), rgba(0,0,0,0)),
    //   radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
    // background-repeat: no-repeat;
    // background-color: white;
    // background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;

    // /* Opera doesn't support this in the shorthand */
    // background-attachment: local, local, scroll, scroll;

    .icon-up {
      margin-top: -3px;
      margin-right: 2px;
    }

    .icon-down {
      margin-bottom: -3px;
      margin-right: 2px;
    }

    .icon-changes {
      margin-right: 5px;
    }

    .icon-hover {
      fill: #999999;
    }
    .tooltip-country-de,
    .tooltip-country-es,
    .tooltip-country-fr,
    .tooltip-country-it,
    .tooltip-country-nl,
    .tooltip-country-pl,
    .tooltip-country-se,
    .tooltip-country-uk,
    .tooltip-country-us {
      padding-left: 16px;
      background-image: url(../../public/assets/flag-de.svg);
      background-position: left 0px center;
      background-size: 12px;
      background-repeat: no-repeat, no-repeat;
    }

    .tooltip-country-es {
      background-image: url(../../public/assets/flag-es.svg);
    }

    .tooltip-country-fr {
      background-image: url(../../public/assets/flag-fr.svg);
    }

    .tooltip-country-it {
      background-image: url(../../public/assets/flag-it.svg);
    }

    .tooltip-country-nl {
      background-image: url(../../public/assets/flag-nl.svg);
    }

    .tooltip-country-pl {
      background-image: url(../../public/assets/flag-pl.svg);
    }

    .tooltip-country-se {
      background-image: url(../../public/assets/flag-se.svg);
    }

    .tooltip-country-uk {
      background-image: url(../../public/assets/flag-uk.svg);
    }

    .tooltip-country-us {
      background-image: url(../../public/assets/flag-us.svg);
    }
  }

  .description.changes-history {
    font-size: 0.9em;
    margin-top: -5px;
  }

  .chart-tooltip-changes-before-after {
    display: flex;
    justify-content: flex-end;
  }
}
