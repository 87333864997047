.Toastify__toast {
  border-radius: 10px !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}

.toast-success {
  background: #54d62c !important;
  color: #fff !important;
}

.toast-error {
  background: #ff4842 !important;
  color: #fff !important;
}

.Toastify__toast-icon {
  color: #fff !important;
}
